import * as React from 'react';

import SEO from '../../components/seo';
import NavBar from '../../components/navBar';
import Footer from '../../components/Footer';
import MyLink from '../../components/MyLink';

import ProjectBcc from '../../images/project_bcc.png';
import ProjectReverse from '../../images/project_reverse.png';
import ProjectWurdle from '../../images/project_wurdle.png';
import ProjectGoals from '../../images/project_goals.png';

// styles
const pageStyles = {
  color: '#232129',
  // padding: 96,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const IndexPage = () => {
  return (
    <div style={pageStyles}>
      <SEO title="Projects" />
      <NavBar />
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Projects</h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              This is a collection of simple projects we've recently built.
            </p>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4" style={{marginTop: -5}}>
              All have been under 1 week of development time.
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-4 lg:max-w-none">
            {posts.map(post => (
              <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <MyLink className="flex-shrink-0" href={post.href} eventName={post.eventName} linkType="page_link">
                  <img
                    className="h-60 w-full object-cover object-top"
                    // style={{ marginTop: -80 }}
                    src={post.image}
                    alt=""
                  />
                </MyLink>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-blue-600">
                      {post.categories.map((category, index) => {
                        const lastIndex = post.categories.length - 1 == index;
                        const showComma = post.categories.length > 1 && !lastIndex;
                        return (
                          <MyLink
                            key={category.name}
                            href={category.href}
                            className={`hover:underline ${showComma ? 'mr-2' : ''}`}
                            linkType="outbound_link"
                            eventName={category.eventName}
                          >
                            {category.name + (showComma ? ',' : '')}
                          </MyLink>
                        );
                      })}
                    </p>
                    <MyLink href={post.href} className="block mt-2" linkType="page_link" eventName={post.eventName}>
                      <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                      <p className="mt-3 text-base text-gray-500">{post.description}</p>
                    </MyLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndexPage;

/* This example requires Tailwind CSS v2.0+ */
const posts = [
  {
    title: 'Goals Calendar',
    href: '/projects/goals',
    eventName: 'project_goals_clicked',
    categories: [
      {
        name: 'iOS',
        href: 'https://apps.apple.com/us/app/id1660459952',
        eventName: 'ios_goals_clicked',
      },
      {
        name: 'Android',
        href: 'https://play.google.com/store/apps/details?id=io.one_step.goals',
        eventName: 'android_goals_clicked',
      },
    ],
    description: 'Visualize your goals over time and see when you are trying to do too much at once.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'https://is1-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/3d/51/83/3d518330-30e3-b3b8-f900-689232b07658/4e737a79-6947-447e-a562-f512a2da9762_2.png/600x0w.webp',
    image: ProjectGoals,
  },
  {
    title: 'Bcc Text',
    href: '/projects/bcc-text',
    eventName: 'project_bcc_text_clicked',
    categories: [
      {
        name: 'iOS',
        href: 'https://apps.apple.com/us/app/bcc-text/id1613103349',
        eventName: 'ios_bcc_text_clicked',
      },
    ],
    description:
      'Send Bcc texts like you would in an email. Save time - avoid copy/pasting a message to send to each person.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'https://is1-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/3d/51/83/3d518330-30e3-b3b8-f900-689232b07658/4e737a79-6947-447e-a562-f512a2da9762_2.png/600x0w.webp',
    image: ProjectBcc,
  },
  {
    title: 'Reverse Streaks',
    href: '/projects/reverse-streaks',
    eventName: 'project_motivation_streaks_clicked',
    categories: [
      {
        name: 'iOS',
        href: 'https://apps.apple.com/us/app/motivation-streaks/id1617505203',
        eventName: 'ios_motivation_streaks_clicked',
      },
      {
        name: 'Android',
        href: 'https://play.google.com/store/apps/details?id=io.one_step.motivation_streaks',
        eventName: 'android_motivation_streaks_clicked',
      },
    ],
    description:
      'This app is different than the typical Streaks you are used to. The idea behind it, is to keep track how you feel after you work on a project/hobby.',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    imageUrl:
      'https://is4-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/41/8b/5c/418b5c12-d772-7e00-5e6d-8239b1880b8b/99c673fe-b642-4dba-9717-42a90703ddf8_2.png/600x0w.webp',
    image: ProjectReverse,
  },
  {
    title: 'Wurdle with Friends',
    href: '/projects/wurdle-with-friends',
    eventName: 'project_wurdle_clicked',
    categories: [
      {
        name: 'iOS',
        href: 'https://apps.apple.com/us/app/wurdle-with-friends/id1616260888',
        eventName: 'ios_wurdle_clicked',
      },
      {
        name: 'Android',
        href: 'https://play.google.com/store/apps/details?id=io.one_step.wordle_tracker',
        eventName: 'android_wurdle_clicked',
      },
    ],
    description: 'Enjoy Wordle but want to make it more social with your inner circle? Compete with friends in Wordle!',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    imageUrl:
      'https://is2-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/aa/71/ce/aa71ce19-8d4c-8a2f-8960-c69e7f185a1d/8e34ad01-889e-4d3d-87f2-dbdb0384ec15_1.png/600x0w.webp',
    image: ProjectWurdle,
  },
];
